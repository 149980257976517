<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 98.7 98.7"
        class="icon"
    >
        <path
            style="fill-rule: evenodd; clip-rule: evenodd;"
            d="M12.7 0h73.4c7 0 12.7 5.7 12.7 12.7v73.4c0 7-5.7 12.7-12.7 12.7H12.7C5.7 98.7 0 93 0 86.1V12.7C0 5.7 5.7 0 12.7 0zm59.2 11c-2.4 0-4.4 2-4.4 4.4V26c0 2.4 2 4.4 4.4 4.4h11.2c2.4 0 4.4-2 4.4-4.4V15.4c0-2.4-2-4.4-4.4-4.4H71.9zm15.7 30.8h-8.7c.8 2.7 1.3 5.5 1.3 8.5C80.1 66.6 66.4 80 49.5 80S18.8 66.7 18.8 50.3c0-2.9.4-5.8 1.3-8.5H11v41.7c0 2.2 1.8 3.9 3.9 3.9h68.8c2.2 0 3.9-1.8 3.9-3.9V41.8zM49.5 29.9c-11 0-19.8 8.6-19.8 19.2s8.9 19.2 19.8 19.2c11 0 19.8-8.6 19.8-19.2s-8.9-19.2-19.8-19.2z"
        />
    </svg>
</template>

<script>
export default {
    name: 'iconInstagram',
    components: {},
};
</script>

<style scoped></style>
