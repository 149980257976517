<template>
    <main>
        <div class="container max-width main-content">
            <section v-if="videoId" class="main-content--video">
                <h2 class="heading">Featured Video</h2>
                <you-tube-video class="feature-video" :videoId="videoId" />
            </section>
            <div class="main-content--sidebar">
                <featured-shows class="featured-shows" />
                <no-shows-promo v-if="minimalShows" class="no-shows-promo" />
            </div>
        </div>
        <section>
            <most-recent-tweet />
        </section>

        <about-section></about-section>

        <section class="container max-width">
            <featured-posts />
        </section>
    </main>
</template>

<script>
import sanity from '../sanity';
import AboutSection from '../components/AboutSection.vue';
import FeaturedShows from '../components/FeaturedShows.vue';
import FeaturedPosts from '../components/FeaturedPosts.vue';
import MostRecentTweet from '../components/MostRecentTweet.vue';
import NoShowsPromo from '../components/NoShowsPromo.vue';
import YouTubeVideo from '../components/YouTubeVideo.vue';
import { getFutureShows } from '../services/shows.service';

const query = `*[_type == "video" && feature == true] | [0]{id}`;

export default {
    name: 'Home',
    components: {
        AboutSection,
        FeaturedShows,
        FeaturedPosts,
        MostRecentTweet,
        NoShowsPromo,
        YouTubeVideo,
    },
    data() {
        return {
            loading: true,
            error: null,
            videoId: '',
            minimalShows: Boolean,
        };
    },
    created() {
        this.fetchData();
    },
    watch: {
        $route: 'fetchData',
    },
    methods: {
        fetchData() {
            this.error = null;
            this.loading = true;
            getFutureShows(2).then((shows) => (this.minimalShows = shows.length <= 1));

            sanity.fetch(query).then(
                (video) => {
                    this.videoId = video.id;
                    this.loading = false;
                },
                (error) => {
                    this.error = error;
                }
            );
        },
    },
};
</script>

<style scoped>
.main-content--video {
    margin-bottom: 20px;
}

@media (min-width: 768px) {
    .main-content {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    .main-content--video {
        flex: 1;
        margin-right: 2em;
        max-width: 800px;
        margin-bottom: 0;
    }
    .main-content--sidebar {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 0 1 280px;
    }
}

.feature-video {
    max-width: 900px;
    border: 5px solid var(--accent);
}

.featured-shows {
    margin-bottom: 20px;
}
</style>
