<template>
    <main class="container max-width shows-page">
        <div class="shows">
            <h1 class="heading">Shows</h1>
            <p v-if="totalShows == 0">No upcoming shows yet, but stay tuned!</p>
            <div v-if="totalShows >= 0">
                <ul v-for="(shows, month) in shows" :key="month">
                    <h2 class="shows__month">{{ month }}</h2>
                    <ul class="shows-list">
                        <li class="shows-list__show" v-for="show in shows" :key="show._id">
                            <show :show="show"></show>
                        </li>
                    </ul>
                </ul>
            </div>
        </div>
        <no-shows-promo class="support-matt" />
    </main>
</template>

<script>
import { getFutureShows } from '../services/shows.service';
import { getMonthFullName } from '../services/date.service';
import { ErrorMessage } from '../const';
import NoShowsPromo from '../components/NoShowsPromo.vue';
import Show from '../components/Show.vue';

export default {
    components: {
        NoShowsPromo,
        Show,
    },
    data() {
        return {
            loading: true,
            error: null,
            shows: [],
            totalShows: Number,
        };
    },
    created() {
        this.fetchData();
    },
    watch: {
        $route: 'fetchData',
    },
    methods: {
        fetchData() {
            this.error = null;
            this.loading = true;
            getFutureShows().then(
                (shows) => {
                    this.totalShows = shows.length;
                    this.shows = shows.reduce(function (byMonth, show) {
                        var month = getMonthFullName(show.date.month.abbr);

                        if (!byMonth.hasOwnProperty(month)) {
                            byMonth[month] = [];
                        }

                        byMonth[month].push(show);

                        return byMonth;
                    }, {});
                    this.loading = false;
                },
                (error) => {
                    this.error = ErrorMessage.SHOWS;
                    console.error(this.error);
                }
            );
        },
    },
};
</script>

<style scoped>
.shows__month {
    margin-top: 30px;
    color: var(--accent);
    border-bottom: 1px solid var(--accent);
    text-transform: uppercase;
}
.shows-list__show {
    border-bottom: 1px dashed var(--gray-medium);
}
.shows-list__show:last-child {
    border-bottom: none;
}
@media (min-width: 600px) {
    .shows {
        margin-right: 20px;
    }
    .shows-page {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }
    .support-matt {
        flex-basis: 350px;
    }
}
</style>
