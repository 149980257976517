<template>
    <section class="container max-width about">
        <div class="about__content">
            <h2 class="heading about__heading">{{ page.title }}</h2>
            <div
                v-if="page.homePageAbout && page.homePageAbout.length"
                class="text--large"
            >
                <block-content :blocks="page.homePageAbout" />
            </div>
        </div>

        <travel-section></travel-section>

        <ul class="about__photos">
            <li
                v-for="photo in photos"
                :key="photo._id"
                class="about__photo"
                :id="'id_' + photo._id"
            >
                <img :src="urlFor(photo.photo).url()" :alt="photo.label" />
            </li>
        </ul>
    </section>
</template>

<script>
import sanity from '../sanity';
import imageUrlBuilder from '@sanity/image-url';
import BlockContent from 'sanity-blocks-vue-component';
import TravelSection from '../components/TravelSection.vue';

const builder = imageUrlBuilder(sanity);
const query = `*[_type == "pageAbout"] | [0]`;
const picsQuery = `*[_type == "photo" && references(*[_type=="photo-category" && slug.current=="about"]._id)] `;

export default {
    components: {
        BlockContent,
        TravelSection,
    },
    data() {
        return {
            loading: true,
            error: null,
            page: {},
            photos: [],
        };
    },
    computed: {
        hasFeatureImage: function () {
            return this.page.image && this.page.image.asset;
        },
    },
    created() {
        this.fetchData();
    },
    watch: {
        $route: 'fetchData',
    },
    methods: {
        fetchData() {
            this.error = null;
            this.loading = true;
            sanity.fetch(query).then(
                (page) => {
                    this.page = page;
                    this.loading = false;
                },
                (error) => {
                    this.error = error;
                }
            );

            sanity.fetch(picsQuery).then((photos) => {
                this.photos = photos;
            });
        },
        urlFor(source) {
            return builder.image(source);
        },
    },
};
</script>

<style scoped>
.about__photos {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.about__photo {
    margin: 0 15px;
    margin-top: 15px;
}
.about__photo img {
    max-width: 230px;
    max-height: 100px;
}

/* Manually order the NJ festival image 
   at the end of the list */
#id_8d116923-cb7b-4e27-9f93-9c2756255b95 {
    order: 1;
}
</style>
