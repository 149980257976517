<template>
    <main class="main-content">
        <div class="container max-width">
            <h1 class="heading shop__heading">{{ page.title }}</h1>
            <p class="shop__intro">{{page.intro}}</p>

            <div class="products__list">
                <div v-for="product in products"
                    :key="product._id"
                    class="product__details">
                    <h2 class="heading product__heading">{{product.name}}</h2>
                    <img v-if="product.image && product.image.asset"
                        class="product__feature-image"
                        :src="urlFor(product.image).url()"
                        alt="" />
                    <div class="product__description"
                        v-if="product.description && product.description.length">
                        <block-content
                            class="product__description"
                            :blocks="product.description" />
                        <a class="button"
                        :href="product.link"
                        target="_blank" >Buy this!</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="book-pics" v-if="photos">
            <div class="container max-width book-pics__text"
                 v-if="page.showPicturesContent && page.showPicturesContent.length">
                <block-content :blocks="page.showPicturesContent" />
            </div>
            <ul class="book-pics__photos">
                <li v-for="photo in photos"
                    :key="photo._id"
                    class="book-pics__photo">
                    <img :src="urlFor(photo.photo).size(200, 200).url()" alt=""/>
                </li>
            </ul>
        </div>
    </main>
</template>

<script>
import sanity from '../sanity';
import imageUrlBuilder from '@sanity/image-url';
import BlockContent from 'sanity-blocks-vue-component';

const builder = imageUrlBuilder(sanity);
const query = `*[_type == "pageShop"] | [0]`;
const productsQuery = `*[_type == "product"]`;
const picsQuery = `*[_type == "photo" && references(*[_type=="photo-category" && slug.current=="book" ]._id)] `;

export default {
    name: 'Shop',
    components: {
        BlockContent,
    },
    data() {
        return {
            loading: true,
            error: null,
            page: {},
            products: [],
            photos: [],
        };
    },
    created() {
        this.fetchData();
    },
    watch: {
        $route: 'fetchData',
    },
    methods: {
        fetchData() {
            this.error = null;
            this.loading = true;
            sanity.fetch(query).then(
                (page) => {
                    this.page = page;
                    this.loading = false;
                },
                (error) => {
                    this.error = error;
                }
            );

            sanity.fetch(productsQuery).then((products) => {
                this.products = products;
            });

            sanity.fetch(picsQuery).then((photos) => {
                this.photos = photos;
            });
        },
        urlFor(source) {
            return builder.image(source);
        },
    },
};
</script>

<style scoped>
.shop__intro {
    margin-bottom: 20px;
}
@media (min-width: 768px) {
    .shop__heading {
        margin-bottom: 20px;
    }
}

.product__details {
    margin-bottom: 60px;
}

@media (min-width: 768px) {
    .products__list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        grid-column-gap: 20px;
    }
}

.product__heading {
    margin-bottom: 10px;
}

.product__feature-image {
    max-width: 100%;
    margin-bottom: 20px;
}

.product__description p {
    margin-bottom: 1em;
}

.book-pics {
    border-top: 8px solid var(--accent);
    margin: 20px 0 40px;
}

.book-pics__photos {
    display: flex;
    overflow-x: auto;
}

.book-pics__photo {
    margin-right: 15px;
}
</style>
