import sanityClient from '@sanity/client';

const dataset = process.env.VUE_APP_SANITY_DATA
    ? process.env.VUE_APP_SANITY_DATA
    : process.env.NODE_ENV;

export default sanityClient({
    projectId: 'fh640342',
    dataset,
    useCdn: true,
    // useCdn == true gives fast, cheap responses using a globally distributed cache.
    // Set this to false if your application require the freshest possible
    // data always (potentially slightly slower and a bit more expensive).
});
