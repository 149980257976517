<template>
    <div class="logo">
        Matt Jenkins
    </div>
</template>

<script>
export default {
    name: 'Logo',
    components: {},
};
</script>

<style scoped>
.logo {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-family: Bangers, Arial, sans-serif;
}
</style>
