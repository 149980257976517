<template>
    <ul class="categories">
        <router-link
            v-for="category in categories"
            :key="category._id"
            class="categories__button"
            tag="li"
            :to="`/${type}s/${category.slug.current}`"
            exact
        >
            <a>{{ category.title }}</a>
        </router-link>

        <router-link
            v-for="link in add"
            :key="link._id"
            class="categories__button"
            tag="li"
            :to="`/${type}s/${link.slug}`"
            exact
        >
            <a>{{ link.title }}</a>
        </router-link>
    </ul>
</template>

<script>
import sanity from '../sanity';

export default {
    name: 'Categories',
    props: {
        type: { type: String },
        exclude: { type: String },
        add: { type: Array },
    },
    data() {
        return {
            loading: true,
            error: null,
            categories: [],
        };
    },
    created() {
        this.fetchData();
    },
    watch: {
        $route: 'fetchData',
    },
    methods: {
        fetchData() {
            this.error = null;
            this.loading = true;

            sanity
                .fetch(
                    `*[_type == "${this.type}-category" && !(slug.current in ${this.exclude})] | order( title asc)`
                )
                .then(categories => {
                    this.categories = categories;
                });
        },
    },
};
</script>

<style scoped>
.categories {
    font-size: 14px;
    margin-bottom: 20px;
}
@media (min-width: 600px) {
    .categories {
        flex-shrink: 0;
        margin: 0 20px 0 0;
    }
}

.categories__button a,
.categories__button a:hover,
.categories__button a:focus {
    text-decoration: none;
}
.categories__button {
    display: inline-block;
    padding: 2px 12px;
}
.categories__button:hover,
.categories__button:focus {
}
@media (min-width: 600px) {
    .categories__button {
        display: block;
    }
}

.router-link-active {
    border: 1px solid var(--link);
}
</style>
