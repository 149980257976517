<template>
    <form
        class="contact-form"
        name="contact"
        method="POST"
        data-netlify="true"
        action="contact/success"
        @submit.prevent="handleSubmit"
    >
        <input type="hidden" name="form-name" value="contact" />
        <div class="contact-form__group">
            <label for="contact-name">Name:</label>
            <input
                type="text"
                id="contact-name"
                name="name"
                v-model="form.name"
            />
        </div>
        <div class="contact-form__group">
            <label for="contact-email">
                Email:
            </label>
            <input
                type="email"
                id="contact-email"
                name="email"
                v-model="form.email"
            />
        </div>
        <div class="contact-form__group">
            <label for="contact-message">
                Message:
            </label>
            <textarea
                id="contact-message"
                name="message"
                v-model="form.message"
            />
        </div>
        <label class="bot-field">
            Don’t fill this out if you're human:
            <input name="check" type="text" v-model="form.check" />
        </label>
        <button class="button" type="submit">Submit</button>
    </form>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            form: {
                name: '',
                email: '',
                message: '',
                check: '',
            },
        };
    },
    methods: {
        encode(data) {
            return Object.keys(data)
                .map(
                    key =>
                        `${encodeURIComponent(key)}=${encodeURIComponent(
                            data[key]
                        )}`
                )
                .join('&');
        },
        handleSubmit() {
            const axiosConfig = {
                header: { 'Content-Type': 'application/x-www-form-urlencoded' },
            };
            axios
                .post(
                    '/',
                    this.encode({
                        'form-name': 'contact',
                        ...this.form,
                    }),
                    axiosConfig
                )
                .then(() => {
                    this.$router.push('contact/success');
                })
                .catch(() => {
                    this.$router.push('contact/oops');
                });
        },
    },
};
</script>

<style scoped>
.contact-form {
    max-width: 600px;
}

.contact-form__group {
    margin-bottom: 30px;
}

.contact-form label {
    display: block;
    margin-bottom: 5px;
}

.contact-form input,
.contact-form textarea {
    width: 100%;
    font-size: 18px;
    line-height: 1.6em;
    padding: 4px;
    font-family: 'Open Sans', sans-serif;
    box-shadow: 0;
    border: 1px solid var(--gray-medium);
    border-radius: 4px;
}

.contact-form textarea {
    height: 200px;
}

.bot-field {
    display: none !important;
}
</style>
