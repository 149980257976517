<template>
    <div class="tweet" v-if="tweet.id_str">
        <div class="tweet__container">
            <div class="tweet__content">
                <icon-twitter />
                {{ tweet.full_text }}
                <span class="tweet__author">@YOUNGFUNE</span>
            </div>
            <a
                class="tweet__retweet-link"
                :href="
                    `https://twitter.com/intent/retweet?tweet_id=${tweet.id_str}`
                "
            >
                <icon-retweet />
            </a>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

import IconRetweet from '@/components/icons/IconRetweet.vue';
import IconTwitter from '@/components/icons/IconTwitter.vue';

export default {
    name: 'MostRecentTweet',
    components: {
        IconRetweet,
        IconTwitter,
    },
    data() {
        return {
            tweet: {},
        };
    },
    created() {
        this.fetchTweet();
    },
    watch: { $route: 'fetchTweet' },
    methods: {
        fetchTweet() {
            axios
                .get('/.netlify/functions/twitter-timeline', {
                    params: {
                        count: 1,
                    },
                })
                .then(res => {
                    this.tweet = res.data[0];
                });
        },
    },
};
</script>

<style scoped>
.tweet {
    padding: 10px;
    font-size: 15px;
    text-align: right;
    background-color: var(--highlight);
}

.tweet__container {
    display: flex;
    justify-content: center;
}

.tweet__content {
    max-width: 600px;
}

.tweet__retweet-link {
    display: flex;
    align-items: center;
    margin-left: 20px;
    color: var(--white);
    font-size: 1.5em;
    transition: 0.2s transform;
}
.tweet__retweet-link:hover {
    transform: scale(1.1);
}
.tweet__author {
    margin-left: 10px;
    font-weight: bold;
    font-size: 0.7em;
    letter-spacing: 0.1em;
}
</style>
