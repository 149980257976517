<template>
    <article
        v-if="post"
        class="post"
        itemscope=""
        itemtype="http://schema.org/BlogPosting"
        :style="`background-image: url(${imageUrl});`"
    >
        <header class="featured-post__details">
            <h2 class="featured-post__title" itemprop="headline">
                <router-link :to="`/news/${post.slug ? post.slug.current : ''}`">
                    {{ post.title }}
                </router-link>
            </h2>
            <time class="featured-post__date" :datetime="post.date" itemprop="datePublished">
                {{ date }}
            </time>
        </header>
    </article>
</template>

<script>
import configedSanityClient from '@/sanity';
import imageUrlBuilder from '@sanity/image-url';
var moment = require('moment');

const builder = imageUrlBuilder(configedSanityClient);

export default {
    name: 'Post',
    props: {
        post: { type: Object },
        snippet: [Boolean, Number],
    },
    computed: {
        hasFeatureImage: function () {
            return this.post.image && this.post.image.asset;
        },
        imageUrl: function () {
            return this.post.image ? builder.image(this.post.image) : false;
        },
        date: function () {
            return moment(this.post.date).format('MMMM D, YYYY');
        },
    },
    data: function () {
        return {
            snippedPost: '',
            isPostPage: Boolean,
        };
    },
    mounted() {
        this.isPostPage = this.$route.name == 'post';

        if (this.post && this.post.content) {
            const length = typeof this.snippet === 'number' ? this.snippet : 100;
            this.snippedPost = this.toPlainText(this.post.content, length);
        }
    },
    components: {},
    methods: {
        toPlainText(blocks = [], length = 100) {
            const string = blocks
                // loop through each block
                .map((block) => {
                    // if it's not a text block with children,
                    // return nothing
                    if (block._type !== 'block' || !block.children) {
                        return '';
                    }
                    // loop through the children spans, and join the
                    // text strings
                    return block.children.map((child) => child.text).join('');
                })
                // join the parapgraphs
                .join(' ');
            return string.slice(0, length) + '...';
        },
    },
};
</script>

<style scoped>
.post {
    position: relative;
    height: 250px;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    font-size: 16px;
    font-family: 'Helvetica Neue', 'Roboto', 'Segoe UI', sans-serif;
}

.featured-post__title {
    font-size: 1.2em;
    text-transform: inherit;
    letter-spacing: 0.05em;
    line-height: 1.3em;
    color: var(--white);
}
.featured-post__title a {
    color: white;
}
.featured-post__title a:hover,
.featured-post__title a:focus {
    color: var(--link);
}

.featured-post__date {
    font-size: 14px;
    font-style: italic;
}

.featured-post__details {
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.8);
}
</style>
