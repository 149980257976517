<template>
    <div v-if="shows.length > 0">
        <h2 class="heading" id="shows">Upcoming Shows</h2>

        <div class="loading" v-if="loading && !error">Loading...</div>
        <div v-if="error" class="error">{{ error }}</div>
        <ul>
            <li class="featured-show" v-for="show in shows.slice(0, 3)" :key="show._id">
                <show :show="show"></show>
            </li>
        </ul>
        <router-link class="button featured-show__more" v-if="shows.length > 3" to="/shows">
            View All Shows
        </router-link>
    </div>
</template>

<script>
import Show from '../components/Show.vue';
import { ErrorMessage } from '../const';
import { getFutureShows } from '../services/shows.service';

export default {
    name: 'FeaturedShows',
    components: {
        Show,
    },
    data() {
        return {
            loading: true,
            error: null,
            shows: [],
        };
    },
    created() {
        this.fetchData();
    },
    watch: {
        $route: 'fetchData',
    },
    methods: {
        fetchData() {
            getFutureShows(4).then(
                (shows) => {
                    this.shows = shows;
                    this.loading = false;
                },
                (error) => {
                    this.error = ErrorMessage.SHOWS;
                    console.error(this.error);
                }
            );
        },
    },
};
</script>

<style scoped>
.featured-show {
    border-bottom: 1px dashed var(--gray-medium);
}
.featured-show:last-child {
    border-bottom: none;
}

.featured-show__more {
    display: block;
    width: 100%;
    margin-top: 15px;
    max-width: 400px;
}
</style>
