<template>
    <div class="travel">
        <h3 class="travel__heading">
            <span class="travel__flag">
                <img :src="urlFor(photo.country.flag).url()" alt />
            </span>
            {{ photo.title }}
        </h3>

        <img
            class="travel__photo"
            :src="urlFor(photo.comedy_photo).size(300, 300)"
            :alt="photo.alt"
        />
        <img
            clas="travel__photo"
            :src="urlFor(photo.travel_photo).size(300, 300)"
            alt
        />
        <hr />
    </div>
</template>

<script>
import configedSanityClient from '@/sanity';
import imageUrlBuilder from '@sanity/image-url';

const builder = imageUrlBuilder(configedSanityClient);

export default {
    name: 'Post',
    props: {
        photo: { type: Object },
    },
    computed: {},
    data: function() {
        return {
            snippedPost: '',
        };
    },
    mounted() {},
    components: {},
    methods: {
        urlFor(source) {
            return builder.image(source);
        },
    },
};
</script>

<style scoped>
.travel {
    --flag-height: 1.5em;
    --flag-width: 2.25em;

    margin-bottom: 40px;
}
.travel__heading {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.travel__flag {
    height: var(--flag-height);
    margin-right: 5px;
}
.travel__flag img {
    height: var(--flag-height);
    width: var(--flag-width);
    object-fit: cover;
}

.travel__photo {
    display: inline-block;
    max-width: 100%;
}
@media (min-width: 600px) {
    .travel__photo {
        margin-right: 15px;
    }
}
</style>
