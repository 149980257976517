import Vue from 'vue';
import App from './App.vue';
import router from './router';
import ga from './ga';

Vue.config.productionTip = false;

const setTitle = to => {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    const nearestWithTitle = to.matched
        .slice()
        .reverse()
        .find(r => r.meta && r.meta.title);

    if (nearestWithTitle)
        document.title = `${nearestWithTitle.meta.title} | Matt Jenkins Comic`;
};

const updateGaPage = path => {
    ga('set', 'page', path);
    ga('send', 'pageview');
};

new Vue({
    router,
    render: h => h(App),
}).$mount('#app');

setTitle(router.currentRoute);
router.beforeEach((to, from, next) => {
    setTitle(to);
    next();
});

updateGaPage(router.currentRoute.path);
router.afterEach(to => updateGaPage(to.path));

if (
    process.env.NODE_ENV === 'development' ||
    window.location.href.includes('netlify')
) {
    console.info('****dev site****');
    var meta = document.createElement('meta');
    meta.setAttribute('NAME', 'ROBOTS');
    meta.setAttribute('CONTENT', 'NOINDEX, NOFOLLOW');
    document.getElementsByTagName('head')[0].appendChild(meta);
}
