<template>
    <div class="featured-posts">
        <h2 class="heading" id="shows">News</h2>
        <ul class="featured-posts__list">
            <li v-for="post in posts" :key="post._id" class="featured-posts__post">
                <featured-post :post="post" :snippet="175" />
            </li>
            <li v-if="featuredPost" class="featured-posts__post featured-posts__post--featured">
                <featured-post :post="featuredPost" :snippet="175" />
            </li>
        </ul>
        <div class="featured-posts__more">
            <router-link class="button" :to="'/news'">More News</router-link>
        </div>
    </div>
</template>

<script>
import sanity from '../sanity';
import FeaturedPost from './FeaturedPost.vue';

const query = `*[_type == "post" && featured!= true]| order(date desc)[0...2]`;
const featuredPostQuery = `*[_type == "post" && featured == true]| order(date desc)[0]`;

export default {
    components: {
        FeaturedPost,
    },
    data() {
        return {
            loading: true,
            error: null,
            posts: [],
            featuredPosts: [],
            featuredPost: null,
        };
    },
    created() {
        this.fetchData();
    },
    watch: {
        $route: 'fetchData',
    },
    methods: {
        fetchData() {
            this.error = null;
            this.loading = true;

            sanity.fetch(featuredPostQuery).then(
                (post) => {
                    this.featuredPost = post;
                },
                (error) => {
                    this.error = error;
                }
            );

            sanity.fetch(query).then(
                (posts) => {
                    this.posts = posts;
                    this.loading = false;
                },
                (error) => {
                    this.error = error;
                }
            );
        },
    },
};
</script>

<style scoped>
.featured-posts {
    margin-bottom: 40px;
}
.featured-posts__more {
    text-align: center;
}
@media (min-width: 600px) {
    .featured-posts__list {
        display: flex;
        flex-wrap: wrap;
    }
}

.featured-posts__post {
    margin-bottom: 50px;
}
.featured-posts__post:last-child {
    margin-bottom: 25px;
}
@media (min-width: 600px) {
    .featured-posts__post {
        flex: 1 1 30%;
        margin-right: 40px;
    }
    .featured-posts__post:last-child {
        margin-right: 0;
    }
    .featured-posts__post {
        margin-bottom: 20px;
    }
}

.featured-posts__post--featured {
    border: 5px solid var(--accent);
}
</style>
