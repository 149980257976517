<template>
    <div class="page" id="app">
        <app-header class="page__header"></app-header>

        <div class="page__content">
            <router-view />
        </div>

        <app-footer class="page__footer"></app-footer>
    </div>
</template>

<script>
import AppHeader from '@/components/AppHeader.vue';
import AppFooter from '@/components/AppFooter.vue';

export default {
    name: 'app',
    components: {
        AppHeader,
        AppFooter,
    },
};
</script>

<style>
@import './assets/styles/_index.css';
</style>
