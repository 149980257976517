<template>
    <article v-if="post" class="post" itemscope="" itemtype="http://schema.org/BlogPosting">
        <header>
            <h2 class="post__title" itemprop="headline">
                <router-link v-if="!isPostPage" :to="`/news/${post.slug ? post.slug.current : ''}`">
                    {{ post.title }}
                </router-link>
                <span v-if="isPostPage">{{post.title}}</span>
            </h2>
        </header>
        <footer class="post__date">
            Published <time :datetime="post.date" itemprop="datePublished">{{ date }}</time>
        </footer>

        <div class="post__body">
            <div v-if="hasFeatureImage" class="post__feature-image">
                <img :src="imageUrl" alt="" />
            </div>
            <!-- POST CONTENT -->

            <div class="post__content" v-if="post.content && post.content.length">
                <div v-if="snippet">{{ snippedPost }}</div>
                <block-content v-if="!snippet" :blocks="post.content" />
            </div>
        </div>

        <!-- IMGUR -->
        <blockquote
            v-if="post.imgur"
            class="imgur-embed-pub"
            lang="en"
            :data-id="post.imgur"
        >
            <a :href="`//imgur.com/${post.imgur}`">
                World&#39;s First Standupshot Special
            </a>
        </blockquote>
    </article>
</template>

<script>
import configedSanityClient from '@/sanity';
import imageUrlBuilder from '@sanity/image-url';
import BlockContent from 'sanity-blocks-vue-component';
var moment = require('moment');

const builder = imageUrlBuilder(configedSanityClient);

export default {
    name: 'Post',
    props: {
        post: { type: Object },
        snippet: [Boolean, Number],
    },
    computed: {
        hasFeatureImage: function() {
            return this.post.image && this.post.image.asset;
        },
        imageUrl: function() {
            return this.post.image ? builder.image(this.post.image) : false;
        },
        date: function() {
            return moment(this.post.date).format('MMMM D, YYYY');
        },
    },
    data: function() {
        return {
            snippedPost: '',
            isPostPage: Boolean
        };
    },
    mounted() {
        this.isPostPage = this.$route.name == 'post';

        if (this.post && this.post.content) {
            const length = typeof this.snippet === 'number' ? this.snippet : 100;
            this.snippedPost = this.toPlainText(this.post.content, length);
        }
    },
    components: {
        BlockContent,
    },
    methods: {
        toPlainText(blocks = [], length = 100) {
            const string = blocks
                // loop through each block
                .map(block => {
                    // if it's not a text block with children,
                    // return nothing
                    if (block._type !== 'block' || !block.children) {
                        return '';
                    }
                    // loop through the children spans, and join the
                    // text strings
                    return block.children.map(child => child.text).join('');
                })
                // join the parapgraphs
                .join(' ');
            return string.slice(0, length) + '...';
        },
    },
};
</script>

<style>
.post {
    font-size: 16px;
    font-family: 'Helvetica Neue', 'Roboto', 'Segoe UI', sans-serif;
}

.post__title {
    margin-bottom: 0.5em;
    font-size: 1.3em;
    text-transform: inherit;
    letter-spacing: 0.05em;
    line-height: 1.4em;
    color: var(--white);
}
.post__title a {
    color: white;
}
.post__title a:hover,
.post__title a:focus {
    color: var(--link);
}

.post__date {
    max-width: 500px;
    margin-bottom: 0.625em;
    font-size: 14px;
    font-style: italic;
    padding-bottom: 0.625em;
}

.imgur-embed-iframe-pub,
.post figure img,
.post__feature-image img {
    max-width: 100%;
}

.post__feature-image {
    margin-top: 20px;
    margin-bottom: 20px;
}

.post__content p {
    line-height: 1.7em;
    margin-bottom: 0.625em;
}

/* Card view */
@media (min-width: 600px) {
    .post {
        font-size: 18px;
    }
}
</style>
