<template>
    <footer>
        <div class="footer max-width">
            <div class="social">
                <a class="social__link" href="http://twitter.com/youngfune">
                    <icon-twitter class="social__icon" />
                </a>
                <a
                    class="social__link"
                    href="https://www.instagram.com/mattjenkinscomic/"
                >
                    <icon-instagram class="social__icon" />
                </a>
                <a
                    class="social__link"
                    href="https://www.facebook.com/mattjenkinscomic"
                >
                    <icon-facebook class="social__icon" />
                </a>
                <a
                    class="social__link"
                    href="https://www.youtube.com/channel/UCE6tS3ewPuiUAoKewnxvcSA"
                >
                    <icon-youtube class="social__icon" />
                </a>
            </div>
            <div class="copyright">
                <small>&copy; 2006 - {{ currentYear }} Matt Jenkins</small>
            </div>
        </div>
    </footer>
</template>

<script>
import IconTwitter from '@/components/icons/IconTwitter.vue';
import IconInstagram from '@/components/icons/IconInstagram.vue';
import IconFacebook from '@/components/icons/IconFacebook.vue';
import IconYoutube from '@/components/icons/IconYoutube.vue';
var moment = require('moment');

const currentYear = moment().format('YYYY');

export default {
    name: 'AppFooter',
    components: { IconTwitter, IconFacebook, IconInstagram, IconYoutube },
    data() {
        return {
            currentYear,
        };
    },
};
</script>

<style scoped>
footer {
    padding: 10px;
    background-color: var(--gray-medium);
}
@media (min-width: 500px) {
    footer {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media (min-width: 500px) {
    .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
    }
}

.social {
    display: flex;
    justify-content: center;
}

.social__link {
    display: flex;
    margin-right: 15px;
    color: var(--white);
}
.social__link:hover,
.social__link:focus {
    color: var(--highlight);
}

.social__icon {
    width: 20px;
    height: 20px;
}
@media (min-width: 500px) {
    .social__icon {
        width: 25px;
        height: 25px;
    }
}

.copyright {
    text-align: center;
    font-size: 12px;
}
@media (min-width: 500px) {
    .copyright {
        font-size: 16px;
    }
}
</style>
