<template>
    <nav class="nav">
        <ul class="nav__list">
            <router-link
                v-for="item in navList"
                :key="item.url"
                class="nav__item"
                tag="li"
                :to="item.url"
            >
                <a>{{ item.name }}</a>
            </router-link>
        </ul>
    </nav>
</template>

<script>
export default {
    name: 'Navigation',
    data() {
        return {
            navList: [
                {
                    url: '/shows',
                    name: 'Shows',
                },
                {
                    url: '/news',
                    name: 'News',
                },
                {
                    url: '/photos',
                    name: 'Photos',
                },
                {
                    url: '/contact',
                    name: 'Contact',
                },
                {
                    url: '/shop',
                    name: 'Shop',
                },
            ],
        };
    },
};
</script>

<style scoped>
.nav__list {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
}

.nav__item {
    display: flex;
    align-items: center;
    font-size: 16px;
    padding: 5px 10px;
    text-transform: uppercase;
}

.nav__item:hover,
.nav__item:focus-within,
.router-link-active {
    position: relative;
}
.nav__item:hover::after,
.nav__item:focus-within::after,
.router-link-active::after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: '';
    background-color: var(--highlight);
    height: 3px;
    width: 100%;
}

.nav__item a {
    color: var(--white);
}
.nav__item a:hover,
.nav__item a:focus {
    text-decoration: none;
}
</style>
