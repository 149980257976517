<template>
    <main class="container page__content">
        <post class="page-post" :post="post" />
    </main>
</template>

<script>
import sanity from '../sanity';
import Post from '../components/Post.vue';

export default {
    components: {
        Post,
    },
    props: ['slug'],
    data() {
        return {
            loading: true,
            error: null,
            post: {},
        };
    },
    created() {
        this.fetchData();
    },
    watch: {
        $route: 'fetchData',
    },
    methods: {
        fetchData() {
            this.error = null;
            this.loading = true;

            const query = `*[_type == "post" && slug.current == '${this.slug}'] [0]`;

            sanity.fetch(query).then(
                post => {
                    this.post = post;
                    this.loading = false;
                },
                error => {
                    this.error = error;
                }
            );
        },
    },
};
</script>

<style scoped>
.page-post {
    max-width: 700px;
    margin: auto;
}
</style>
