<template>
    <div class="videowrapper">
        <iframe
            width="560"
            height="315"
            :src="`https://www.youtube.com/embed/${videoId}`"
            allowfullscreen
        ></iframe>
    </div>
</template>

<script>
export default {
    name: 'YouTubeVideo',
    props: {
        videoId: { type: String },
    },
};
</script>

<style scoped>
.videowrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
}
.videowrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
}
</style>
