<template>
    <div>
        <script type="application/ld+json">
            {
                "@context": "https://schema.org",
                "@type": "Event",
                "name": "{{show.name ? show.name : 'Comedy Show: Matt Jenkins'}}",
                "url": "{{show.link ? show.link : 'http://mattjenkinscomic.com/shows/' + show.slug}}",
                "startDate": "{{show.date.raw}}T{{showHour}}:{{show.time.min ? show.time.min : '00'}}",
                "endDate": "{{show.date.raw}}T{{showEndHour}}:00",
                "location": {
                    "@type": "Place",
                    "name": "{{show.location.name}}",
                    "address": {
                        "@type": "PostalAddress",
                        "streetAddress": "{{show.location.address}}",
                        "addressLocality": "{{show.location.city}}",
                        "postalCode": "{{show.location.zip}}",
                        "addressRegion": "{{show.location.state}}",
                        "addressCountry": "{{show.location.country ? show.location.country : 'US'}}"
                    }
                },
                "image": [
                    "https://cdn.sanity.io/images/fh640342/production/1c2627d76af8c9f6c2d59b6715afa443507f0382-666x400.jpg?rect=205,0,400,400&w=300&h=300"
                ],
                "description": "See comedian Matt Jenkins perform at this comedy show.",
                "performer": {
                    "@type": "PerformingGroup",
                    "name": "Matt Jenkins"
                }
            }
        </script>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showHour: 0,
            showEndHour: 23,
        };
    },
    props: {
        show: { type: Object },
    },
    created() {
        this.transformShowData();
    },
    watch: {
        $route: 'transformShowData',
    },
    methods: {
        transformShowData() {
            var showTime = Number(this.show.time.hour);
            showTime = this.show.meridiem == 'AM' ? showTime : showTime + 12;
            this.showEndHour =
                showTime + 2 > 23 ? '23' : (showTime + 2).toString();
            this.showHour =
                showTime < 10 ? '0' + showTime : showTime.toString();

            this.showEndHour =
                this.showEndHour < 10
                    ? '0' + this.showEndHour
                    : this.showEndHour.toString();
        },
    },
};
</script>
