<template>
  <section class="container max-width travel">
    <ul class="travel__flags-list">
      <li v-for="country in countries" :key="country._id" class="travel__flag">
        <img :src="urlFor(country.flag)" :alt="`Flag of ${country.name}`" />
      </li>
    </ul>
    <p class="text--large travel__description">Matt has also performed in <span
         v-for="(country, index) in countries" :key="country._id"> {{ country.name}}<span>{{
             index + 1 === countries.length ? "" : ", "}}{{ index + 1 === countries.length - 1 ? "and " : "" }}</span></span>!
    </p>
  </section>
</template>

<script>
import sanity from '../sanity';
import imageUrlBuilder from '@sanity/image-url';

const builder = imageUrlBuilder(sanity);
const countriesQuery = `*[_type == "country"] `;

export default {
    data() {
        return {
            loading: true,
            error: null,
            countries: {},
        };
    },
    computed: {
        hasFeatureImage: function () {
            return this.page.image && this.page.image.asset;
        },
    },
    created() {
        this.fetchData();
    },
    watch: {
        $route: 'fetchData',
    },
    methods: {
        fetchData() {
            this.error = null;
            this.loading = true;
            sanity.fetch(countriesQuery).then(
                (countries) => {
                    this.countries = countries;
                    this.loading = false;
                },
                (error) => {
                    this.error = error;
                }
            );
        },
        urlFor(source) {
            return builder.image(source);
        },
    },
};
</script>

<style scoped>
.travel {
    --flag-height: 40px;
    --flag-width: 60px;

    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
@media (min-width: 600px) {
    .travel {
        justify-content: flex-start;
        flex-direction: row;
    }
}

.travel__flags-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    flex: 1;
}
@media (min-width: 600px) {
    .travel__flags-list {
        margin-right: 40px;
        margin-bottom: 0;
        flex: 0 1 25%;
    }
}

.travel__flag {
    height: var(--flag-height);
    margin-right: 3px;
    margin-left: 3px;
    margin-bottom: 5px;
}
.travel__flag img {
    height: var(--flag-height);
    width: var(--flag-width);
    object-fit: cover;
}

.travel__description {
    flex: 1;
}
</style>
