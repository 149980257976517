<template>
    <main class="container news-page">
        <h1 class="heading">News</h1>
        <ul class="news">
            <li v-for="post in posts" :key="post._id" class="news__post">
                <post :post="post"></post>
            </li>
        </ul>
    </main>
</template>

<script>
import sanity from '@/sanity';
import Post from '@/components/Post';

const query = `*[_type == "post"]{content[]{..., "asset": asset->},image,imgur,date,title,slug} | order(date desc)[0...10]`;

export default {
    components: {
        Post,
    },
    data() {
        return {
            loading: true,
            error: null,
            posts: [],
        };
    },
    mounted() {
        this.addImgurScript();
    },
    created() {
        this.fetchData();
    },
    watch: {
        $route: 'fetchData',
    },
    methods: {
        fetchData() {
            this.error = null;
            this.loading = true;
            sanity.fetch(query).then(
                (posts) => {
                    this.posts = posts;
                    this.loading = false;
                },
                (error) => {
                    this.error = error;
                }
            );
        },
        addImgurScript() {
            const imgurScript = document.createElement('script');
            imgurScript.setAttribute('src', '//s.imgur.com/min/embed.js');
            imgurScript.setAttribute('charset', 'utf-8');
            document.body.appendChild(imgurScript);
        },
    },
};
</script>

<style>
.news-page {
    max-width: 600px;
    margin: auto;
}

.news__post {
    margin-bottom: 50px;
    padding-top: 20px;
    border-top: 2px solid var(--accent);
    max-width: 600px;
}
</style>
