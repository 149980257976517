import Vue from 'vue';
import Router from 'vue-router';

import Contact from '@/views/contact/Contact.vue';
import ContactForm from '@/views/contact/ContactForm.vue';
import ContactSuccess from '@/views/contact/ContactSuccess.vue';
import ContactFail from '@/views/contact/ContactFail.vue';
import Home from '@/views/Home.vue';
import News from '@/views/News.vue';
import Photos from '@/views/photos/Photos.vue';
import PhotoList from '@/views/photos/PhotoList.vue';
import Post from '@/views/Post.vue';
import Shop from '@/views/Shop.vue';
import Show from '@/views/Show.vue';
import Shows from '@/views/Shows.vue';
import TravelPhotos from '@/views/photos/TravelPhotos';

Vue.use(Router);

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/shows',
            name: 'shows',
            component: Shows,
            meta: {
                title: 'Shows',
            },
        },
        {
            path: '/shows/:slug',
            component: Show,
            props: true,
            meta: {
                title: 'Show Details',
            },
        },
        {
            path: '/shop',
            name: 'shop',
            component: Shop,
            meta: {
                title: 'Shop',
            },
        },
        {
            path: '/news',
            name: 'news',
            component: News,
            meta: {
                title: 'News',
            },
        },
        {
            path: '/news/:slug',
            name: 'post',
            component: Post,
            props: true,
            meta: {
                title: 'News',
            },
        },
        {
            path: '/photos',
            component: Photos,
            meta: {
                title: 'Photos',
            },
            children: [
                {
                    name: 'photos',
                    path: '',
                    redirect: 'shows',
                    component: PhotoList,
                },
                {
                    name: 'travel',
                    path: 'travel',
                    component: TravelPhotos,
                },
                {
                    path: ':slug',
                    props: true,
                    component: PhotoList,
                },
            ],
        },
        {
            path: '/contact',
            component: Contact,
            meta: {
                title: 'Contact',
            },
            children: [
                {
                    path: '',
                    component: ContactForm,
                },
                {
                    path: 'success',
                    component: ContactSuccess,
                },
                {
                    path: 'oops',
                    component: ContactFail,
                },
            ],
        },
        {
            path: '*',
            name: 'index',
            component: Home,
            meta: {
                title: 'Home',
            },
        },
    ],
});
