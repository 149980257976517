<template>
    <main class="container max-width">
        <h2 class="heading">Contact</h2>
        <router-view></router-view>
    </main>
</template>

<script>
export default {};
</script>

<style scoped></style>
