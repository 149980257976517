import sanity from '../sanity';
import DateService from './date.service';

const moment = require('moment');
const today = moment().format('YYYY-MM-DD');
const dateService = new DateService()

const showFields = `{
    name,
    date,
    hour,
    min,
    am,
    slug,
    link,
    private,
    'location': venue->name,
    'city': venue->city,
    'state': venue->state,
    'zip': venue->zip,
    'address': venue->address,
    'country': venue->country
}`;

/**
 * This is creating the public model of "Show"
 */
function mapShowData(show) {
    let { name, hour, min, link, location, address, city, state, country, zip } = show;
    let date = show.date.split('-');

    return {
        name,
        date: {
            raw: show.date,
            full: moment(show.date).format('dddd, MMMM D, YYYY'),
            year: date[0],
            day: date[2],
            month: {
                number: date[1],
                abbr: dateService.getMonthAbbr(date[1])
            },
        },
        time: {
            hour,
            min,
            meridiem: show.am ? 'AM' : 'PM',
        },
        location: {
            name: location,
            address,
            city,
            state,
            cityState: formatCityState(city, state),
            zip,
            country,
        },
        private: show.private,
        slug: show.slug.current,
        link,
    }
}

function formatCityState(city = "", state = "") {
    return city += state && city.length > 0 ? `, ${state}` : state;
}

/**
 * Public method for getting a lits of all upcoming shows
 * @param {Int} limit 
 */
export function getFutureShows(limit = 0) {
    let query = `*[_type == "show" && date >= '${today}'] | order(date asc)`
    if (limit > 0) {
        query += ` [0..${limit - 1}]`
    }
    query += showFields;

    return sanity.fetch(query).then(
        shows => shows.map(
            show => mapShowData(show)
        ),
        (error) => {
            console.error(error);
        }
    );
}

export function getShow(slug) {
    let query = `*[_type == "show" && slug.current == '${slug}'] ${showFields}`;

    return sanity.fetch(query).then(
        shows => {
            if (shows.length > 0) {
                return mapShowData(shows[0])
            } else {
                throw `Show ${slug} does not exist.`
            }
        }
    );
}