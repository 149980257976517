<template>
    <div class="show">
        <ShowSchema v-if="!show.private" :show="show"></ShowSchema>
        <div class="show__info">
            <!-- TODO: check if heading should be h2/h3 -->
            <h2 class="show__name">
                {{ show.name ? show.name : show.location.name }}
            </h2>
            <div>
                <div class="show__location" v-if="show.name && show.location.name">
                    {{ show.location.name }}
                </div>
                <p class="show__address" v-if="!show.private">
                    {{ show.location.address }}
                    <span class="show__city-state" v-if="show.location.cityState">
                        <span v-if="show.location.address">-</span>
                        {{ show.location.cityState }}
                    </span>
                </p>
                <p class="show__private-flag" v-if="show.private">PRIVATE</p>
                <p class="show__link" v-if="show.link && !show.private">
                    <a :href="show.link" target="_blank">
                        MORE INFO
                        <span class="sr-only">on {{ show.name }} show</span>
                    </a>
                </p>
            </div>
        </div>

        <div class="show__date">
            <div class="show__day">{{ show.date.day }}</div>
            <div>
                <div class="show__month">{{ show.date.month.abbr }}</div>
                <div class="show__time" v-if="show.time.hour">
                    {{ show.time.hour }}
                    <span v-if="show.min">:{{ show.time.min }}</span>
                    <span class="show__ampm">{{ show.time.meridiem }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ShowSchema from '../components/ShowSchema.vue';

export default {
    components: {
        ShowSchema,
    },
    props: {
        show: { type: Object },
    },
    created() {},
    methods: {},
};
</script>

<style scoped>
.show {
    --details-color: var(--gray-lightest);
    --details-font-size: 0.7em;

    display: flex;
    padding: 0.5em 0;
    font-size: 18px;
}

.show__info {
    order: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.show__name {
    margin-bottom: 0;
    color: var(--white);
    font-size: 1em;
    font-family: inherit;
    font-weight: bold;
    line-height: 1.3;
    letter-spacing: 0.1em;
    text-transform: uppercase;
}

.show__location {
    font-size: var(--details-font-size);
    margin-bottom: 7px;
}

.show__address {
    margin-top: 0;
    margin-bottom: 5px;
    color: var(--details-color);
    font-size: var(--details-font-size);
    text-transform: uppercase;
}

.show__date {
    order: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 0.6em;
    font-weight: bold;
    line-height: 1;
    text-transform: uppercase;
    text-align: center;
}

.show__day {
    font: 40px Tahoma, Geneva, sans-serif;
    font-weight: bold;
    letter-spacing: 0.1em;
    text-align: center;
}

.show__month {
    font-size: 1em;
    letter-spacing: 0.3em;
}

.show__time {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--details-color);
    font-size: 1em;
    padding-top: 0.2em;
}

.show__ampm {
    display: inline-block;
    margin-left: 3px;
    font-size: 0.6em;
    letter-spacing: 0.09em;
}

.show__private-flag {
    padding: 0 0.5em;
    margin-top: 0.5em;
    margin-bottom: 0;
    border: 1px solid var(--gray-medium);
    color: var(--details-color);
    font-size: var(--details-font-size);
    text-align: center;
    max-width: 100px;
}

.show__link {
    padding: 0;
    margin: 0;
    font-size: var(--details-font-size);
}
.show__link a {
    color: var(--highlight);
}
</style>
