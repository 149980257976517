<template>
    <div>
        <h2 class="sr-only">International Travel</h2>
        <ul>
            <li
                v-for="photo in photos"
                :key="photo._id"
                class="photo-list__photo"
            >
                <travel-photo :photo="photo"></travel-photo>
            </li>
        </ul>
    </div>
</template>

<script>
import sanity from '@/sanity';

import TravelPhoto from '../../components/TravelPhoto';

export default {
    name: 'TravelPhotoList',
    components: {
        TravelPhoto,
    },
    data() {
        return {
            loading: true,
            error: null,
            photos: [],
        };
    },
    created() {
        this.fetchData();
    },
    watch: {
        $route: 'fetchData',
    },
    methods: {
        fetchData() {
            const query = `*[_type == "travel-photo"]{_id, alt, travel_photo, comedy_photo, title, country->{flag}}`;

            this.error = null;
            this.loading = true;
            sanity.fetch(query).then(
                photos => {
                    this.photos = photos;
                    this.loading = false;
                },
                error => {
                    this.error = error;
                }
            );
        },
    },
};
</script>

<style scoped></style>
