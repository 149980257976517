<template>
    <div>
        <p class="form__error-message">
            <span class="icon"><icon-alert /></span>
            Sorry... Something happened. :( Try again?
        </p>
        <contact-form></contact-form>
    </div>
</template>

<script>
import IconAlert from './../../components/icons/IconAlert';
import ContactForm from './ContactForm';

export default {
    components: { ContactForm, IconAlert },
};
</script>

<style scoped>
.form__error-message {
    display: inline-flex;
    align-items: center;
    margin: 20px 0;
    padding: 5px 15px 5px 10px;
    border: 1px solid var(--accent);
}
.icon {
    display: flex;
    margin-right: 5px;
    font-size: 25px;
    color: var(--accent);
}
</style>
