<template>
    <section class="no-shows-promo">
        <h2 class="heading">Support Matt!</h2>
        <p class="no-shows-promo__text">
            Please consider purchasing Matt's book or set of greeting cards to support Matt while
            shows are scarce because of Covid :)
        </p>
        <router-link class="button button--filled" to="/shop">Available in the store!</router-link>
    </section>
</template>

<script>
export default {};
</script>

<style scoped>
.no-shows-promo {
    font-size: 15px;
    padding: 15px;
    background-color: var(--gray-dark);
}

.no-shows-promo__text {
    margin-bottom: 20px;
}
</style>