<template>
    <header>
        <div class="header">
            <div class="header__container max-width">
                <router-link to="/">
                    <logo class="header__logo"></logo>
                </router-link>
                <navigation></navigation>
            </div>
        </div>
        <div class="header__sub-headline">
            <div class="max-width">Clean Stand Up Comic, Writer</div>
        </div>
    </header>
</template>

<script>
import Logo from './Logo.vue';
import Navigation from './Navigation.vue';

export default {
    name: 'AppHeader',
    components: {
        Logo,
        Navigation,
    },
};
</script>

<style scoped>
header {
    --side-padding: 10px;
    background-color: var(--gray-medium);
}

.header {
    padding: 0 var(--side-padding);
}
@media (min-width: 768px) {
    .header__container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}

.header__sub-headline {
    padding: 5px var(--side-padding);
    margin: auto;
    background-color: var(--gray-dark);
    font-size: 12px;
    letter-spacing: 0.15em;
    font-family: Helvetica Neue, Roboto, Segoe UI, sans-serif;
    text-transform: uppercase;
}

.header__logo {
    color: var(--white);
}
</style>
