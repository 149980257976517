<template>
    <div>
        <ul class="photo-list">
            <li
                v-for="photo in photos"
                :key="photo._id"
                class="photo-list__photo"
            >
                <img
                    :src="
                        urlFor(photo.photo)
                            .size(300, 300)
                            .url()
                    "
                    alt
                />
            </li>
        </ul>
    </div>
</template>

<script>
import sanity from '@/sanity';
import imageUrlBuilder from '@sanity/image-url';

const builder = imageUrlBuilder(sanity);

export default {
    name: 'PhotoList',
    props: ['slug'],
    data() {
        return {
            loading: true,
            error: null,
            photos: [],
        };
    },
    created() {
        this.fetchData();
    },
    watch: {
        $route: 'fetchData',
    },
    methods: {
        fetchData() {
            const query = this.slug
                ? `*[_type == "photo" && references(*[_type=="photo-category" && slug.current=="${this.slug}"]._id)]`
                : `*[_type == "photo" && references(*[_type=="photo-category" && slug.current!="book" ]._id) ]`;

            this.error = null;
            this.loading = true;
            sanity.fetch(query).then(
                photos => {
                    this.photos = photos;
                    this.loading = false;
                },
                error => {
                    this.error = error;
                }
            );
        },
        urlFor(source) {
            return builder.image(source);
        },
    },
};
</script>

<style scoped>
.photo-list {
    display: flex;
    flex-wrap: wrap;
}

.photo-list__photo {
    display: flex;
    flex-basis: 300px;
    margin-right: 40px;
    margin-bottom: 40px;
}

.photo-list__photo img {
    max-width: 100%;
}
</style>
