<template>
    <main>
        <div class="container">
            <h1 class="heading">Photos</h1>
            <div class="photos">
                <categories
                    type="photo"
                    exclude="['book', 'about']"
                    :add="[{ slug: 'travel', title: 'International' }]"
                />
                <router-view />
            </div>
        </div>
    </main>
</template>

<script>
import Categories from '@/components/Categories.vue';

export default {
    name: 'Photos',
    props: ['slug'],
    components: {
        Categories,
    },
};
</script>

<style scoped>
.photos {
}
@media (min-width: 600px) {
    .photos {
        display: flex;
    }
}
</style>
