<template>
    <div>
        <p>
            Thanks for your message! I will get back to you as soon as I am
            able!
        </p>
    </div>
</template>

<script>
export default {};
</script>
