<template>
    <main class="container">
        <div v-if="loading && !error">Loading...</div>
        <div v-if="error">{{ error }}</div>

        <div v-if="show.date">
            <h1 class="heading">Comedy Show: {{ show.date.full }}</h1>
            <h2 v-if="show.time.hour">
                Time: {{ show.time.hour }}:{{
                    show.time.min ? show.time.min : '00'
                }}
                <span class="ampm">{{ show.time.meridiem }}</span>
            </h2>

            <div v-if="!show.private">
                <h2>
                    Place:
                    {{ show.name ? show.name : show.location.name }}
                </h2>
                <p v-if="show.name && show.location.name">
                    {{ show.location.name }}
                </p>
                <p class="address">
                    {{ show.location.address }}
                    <span v-if="show.location.cityState">
                        , {{ show.location.cityState }}
                    </span>
                </p>
                <p v-if="show.link">
                    <a :href="show.link">
                        More Information
                        <span class="sr-only">about this show</span>
                    </a>
                </p>
            </div>

            <div v-if="show.private">
                <h2>Private Show</h2>
            </div>

            <ShowSchema v-if="!show.private" :show="show"></ShowSchema>
        </div>
    </main>
</template>

<script>
import ShowSchema from '../components/ShowSchema.vue';
import { ErrorMessage } from '../const';
import { getShow } from '../services/shows.service';

export default {
    components: {
        ShowSchema,
    },
    props: ['slug'],
    data() {
        return {
            loading: true,
            error: null,
            show: Object,
        };
    },
    created() {
        this.fetchData();
    },
    watch: {
        $route: 'fetchData',
    },
    methods: {
        fetchData() {
            this.error = null;
            this.loading = true;

            getShow(this.slug).then(
                (show) => {
                    if (!show) {
                        this.$router.push({ path: '/' });
                    }
                    this.show = show;
                    this.loading = false;
                },
                (error) => {
                    this.error = ErrorMessage.SHOWS;
                    console.error(error);
                }
            );
        },
    },
};
</script>

<style scoped>
.address {
    display: flex;
}
.ampm {
    font-size: 0.6em;
}
</style>
